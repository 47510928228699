import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import devices from '../../../js/styles/Devices';
import localeData from '../../../js/localizations/locale-data';

const ProductBlockWrapper = styled.div`
  && {
    text-align: center;
    margin-top: ${(props) => (props.text ? '0' : '1rem')};
  }
`;

const ImageWrapper = styled.div`
  && {
    position: relative;
    max-width: calc(100% + 30px);
    margin-bottom: 1rem;
    margin-left: -1rem;

    @media ${devices.upFromTablet} {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const Image = styled.img`
  && {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const Cost = styled.span`
  && {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, var(--black) 0%, transparent 100%);
    color: var(--white);
    font-size: 1.3rem;
    line-height: 3rem;
    padding: 0 1rem;
  }
`;

const PrefilledApplicationProductBlock = ({
  queryParams,
}) => {
  const {
    objTitle,
    objImg,
    objCost,
  } = queryParams;

  return (
    <ProductBlockWrapper>

      <ImageWrapper>
        {objImg && (
          <Image
            src={objImg}
            alt={objTitle || ''}
          />
        )}

        {objCost && (
          <Cost>
            <FormattedMessage
              id="prefilledApplication.partnerProduct.price"
              defaultMessage="Price: <strong>{price, number, currency}</strong>"
              description="Text for price on prefilled application partner product"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                price: objCost,
                currency: localeData.CURRENCY,
              }}
            />
          </Cost>
        )}
      </ImageWrapper>
    </ProductBlockWrapper>
  );
};

PrefilledApplicationProductBlock.propTypes = {
  queryParams: PropTypes.shape({
    objTitle: PropTypes.string,
    objImg: PropTypes.string,
    objCost: PropTypes.string,
  }),
};

PrefilledApplicationProductBlock.defaultProps = {
  queryParams: {},
};

export default PrefilledApplicationProductBlock;

export const prefilledApplicationProductBlockQuery = graphql`
  fragment prefilledApplicationProductBlockFields on PrefilledApplicationProduct {
    text
  }
`;
