import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { brand } from '../../../js/localizations/current-locale';

import SvgIcon from '../../atoms/SvgIcon';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';

import { useOptions } from '../../../context/options';
import SectionWrapper from '../../common/SectionWrapper';
import LinkBlock from '../../blocks/LinkBlock';
import replaceACFVariables from '../../../libs/utils/replace-acf-variables';
import withSquishing from '../../utils/withSquishing';
import devices from '../../../js/styles/Devices';
import style from './style';

const Title = styled.p`
  && {
    font-family: var(--font-family);
    font-size: var(--h2-desktop-font-size);
    font-weight: ${style.h1FontWeight};
    letter-spacing: ${style.letterSpacing};
    text-align: ${(props) => props.position};
    color: ${(props) => props.titleColor};
    margin: 1rem 0 1.5rem;

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
    }
  }
`;

const FAQColumn = styled.div`
  && {
    background-color: ${style.backgroundColor};
    border-radius: ${style.columnRadius};
    margin-bottom: 1rem;

    &:first-child {
      border-top: ${style.faqColumnBorderTop};
    }

    @media ${devices.upFromSmallTablet} {
      width: ${(props) => props.columnWidth};
      border-top: ${style.faqColumnBorderTop};
    }
  }
`;

const FaqQuestionIcon = styled(SvgIcon)`
  && {
    left: 0;
    height: 1.5rem;
    color: ${style.faqIconColor};
    font-size: 1rem;
    order: ${style.order};
    margin-right: 1rem;
    transform: rotate(${style.originalSvgTransForm});
    transform-origin: center;
    transition: transform ease 0.2s;
    position: ${(props) => props.position};
  }
`;

const FaqQuestionWrap = styled.div`
  && {
    summary {
      color: ${style.faqQuestionColor};
      font-weight: ${style.questionFontWeight};
      display: flex;
      align-items: center;
      justify-content: ${style.questionJustification};
      background-color: ${(props) => props.backgroundColor};
      appearance: none;
      cursor: pointer;
      list-style: none;
      padding:  ${style.summaryPadding};

      &::marker,
      &::-webkit-details-marker {
        display: none;
      }
    }

    details {
      position: relative;
      background-color: ${(props) => props.backgroundColor};
      padding: ${style.questionPadding};

      ul li::before {
        color: ${style.bulletColor};
      }

      &[open] {
        border: ${style.transformBorder};
        border-radius: ${style.detailsBorderRadius};

        ${FaqQuestionIcon} {
          color: ${style.detailsIconColor};
        }

        summary {
          color: ${style.openQuestionColor};
        }

        svg{
          transform: ${style.svgTransformOpen};
          color: ${style.svgColorOpen};
        }
      }
    }

    svg {
      left: ${style.svgLeft};
      height: ${style.svgHeight};
      color: ${style.svgColor};
      font-size: ${style.svgFontSize};
      margin-right: ${style.svgMarginRight};
      transform: ${style.svgTransform};
      transform-origin: ${style.svgTransformOrigin};
      transition: ${style.svgTransition};
      order: ${style.svgOrder};
    }

    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4rem 0;

        &::before {
          color: var(--teal);
          content: '·';
          font-weight: bold;
          margin-right: 0.6rem;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;
        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      tr {
        &:nth-child(odd) {
          background: var(--lightest-grey);
        }
      }

      td {
        padding: 0.5rem 0.8rem;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }
  }
`;

const FaqAnswer = styled.div`
  && {
    font-weight: ${style.answerFontWeight};
    margin: 1rem ${style.margin};
    color: ${style.faqQuestionColor};

    p {
      font-size: ${style.answerFontSize};
    }
  }
`;

const Faq = styled.div`
  && {
    font-family: var(--font-family);
    border-bottom: ${style.borderBottom};
    background-color: ${style.backgroundColorFAQ};
    border-radius: ${style.borderRadius};
    box-shadow: ${style.boxShadow};
    margin: ${style.margin};
    border: ${style.border};
  }
`;

const ButtonWrapper = styled.div`
  && {
    font-family: var(--font-family);
    margin-top: ${style.marginTop};
    text-align: center;
  }
`;

const ExpandButton = styled(LinkBlock)`
  && {
    background-image: none;
    border: 0;
    border-radius: ${style.expandButtonBorderRadius};
    color: var(--dark-teal);
    cursor: pointer;
    font-weight: var(--has-font-weight);
    text-decoration: none;
    font-size: var(--text-font-size);
    padding: 0.8rem;
    display: block;
    width: 100%;
    color: ${style.buttonTextColor};
    background-color: ${style.buttonBackground};
    border-color: ${style.borderColor};
    border-style: ${style.borderStyle} ;
    border-width: ${style.borderWidth};
    text-align: center;
    margin: ${style.expandButtonMargin};

    @media ${devices.upFromTablet} {
      max-width: 30%;
    }
  }
`;

const TitleLine = styled.div`
  && {
    margin: 0 auto;
    width: 7rem;
    height: 3px;
    background-color: var(--sambla-new-red);
    animation:enter .5s cubic-bezier(.25,.46,.45,.94) both;
    animation-delay: 1s;
  }
`;

const FAQContainer = styled.div`
  && {
    @media ${devices.upFromSmallTablet} {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const PureFAQSection = (props) => {
  const {
    acf,
    location,
    variables,
  } = props;

  const getFAQColumns = (faqs, numberOfColumns) => {
    const firstColumn = [];
    const secondColumn = [];
    if (numberOfColumns === 1) {
      if (faqs && faqs.length) {
        for (let i = 0; i < faqs.length; i += 1) {
          firstColumn.push(faqs[i]);
        }
      }
      const columns = [firstColumn];
      return columns;
    }

    if (faqs && faqs.length) {
      for (let i = 0; i < faqs.length; i += 1) {
        if (i % 2) {
          secondColumn.push(faqs[i]);
        } else {
          firstColumn.push(faqs[i]);
        }
      }
    }
    const columns = [firstColumn, secondColumn];
    return columns;
  };

  const renderFAQs = (faq, i, multiplier) => {
    const nonCollapsable = (brand === 'omalaina');

    let dynamicIcon = ['fas', 'chevron-down'];
    switch (brand) {
      case 'digifinans':
        dynamicIcon = ['fal', 'angle-down'];
        break;
      case 'rahalaitos':
        dynamicIcon = ['fas', 'plus'];
        break;
      default:
        dynamicIcon = ['fal', 'chevron-down'];
        break;
    }

    return (
      <Faq
        key={`faq-${i}-${multiplier}`}
        open={nonCollapsable}
      >
        <FaqQuestionWrap>
          <details key={`faq-question-${i}-${multiplier}`} open={nonCollapsable} className={` ${nonCollapsable ? 'non-collapsable-faq' : ''}`}>
            <summary>
              <FaqQuestionIcon
                icon={dynamicIcon}
              />
              {replaceACFVariables(faq.title, variables)}
            </summary>
            <FaqAnswer
              dangerouslySetInnerHTML={
                createMarkup(replaceACFVariables(faq.content, variables))
              }
            />
          </details>
        </FaqQuestionWrap>
      </Faq>
    );
  };

  const { titlePosition, titleColor } = acf.sectionSettings;

  if (!acf.faqs || !acf.faqs.nodes.length) return false;

  const faqColumns = getFAQColumns(acf.faqs.nodes, acf.numberOfColumns);
  const faqColumnWidth = (faqColumns.length > 1) ? 'calc(50% - 2rem)' : 'calc(100% - 2rem)';
  let icon = [];

  if (acf.button && acf.button.icon) {
    icon = acf.button.icon[0].split('/');
  }

  const isStartpage = location.pathname === '/';
  const structuredDataPage = isStartpage
    ? `${location.href}home/`
    : location.href;

  const structuredDataFAQsOnPage = acf.faqs.nodes
    .filter((faq) => {
      if (faq.fAQStructuredData?.structuredDataPage?.node) {
        return faq.fAQStructuredData.structuredDataPage?.node?.link === structuredDataPage;
      }
      return false;
    });

  const faqStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: structuredDataFAQsOnPage.map((faq) => ({
      '@type': 'Question',
      name: replaceACFVariables(faq.title, variables),
      acceptedAnswer: {
        '@type': 'Answer',
        text: replaceACFVariables(faq.content, variables),
      },
    })),
  };

  return (
    <SectionWrapper
      settings={acf.sectionSettings}
    >
      {structuredDataFAQsOnPage.length > 0 && (
        <script type="application/ld+json">{`${JSON.stringify(faqStructuredData)}`}</script>
      )}

      {acf.title && (
        <Title
          position={titlePosition}
          titleColor={titleColor}
        >
          {acf.title}
        </Title>
      )}
      {acf.sectionSettings?.titleLine && (
        <TitleLine />
      )}

      <FAQContainer>
        <FAQColumn
          columnWidth={faqColumnWidth}
          key="faq-first-column"
        >
          {faqColumns[0].map((faq, i) => renderFAQs(faq, i, 1)) }
        </FAQColumn>
        { acf.numberOfColumns > 1 && (
        <FAQColumn
          columnWidth={faqColumnWidth}
          key="faq-second-column"
        >
          {faqColumns[1].map((faq, i) => renderFAQs(faq, i, 10)) }
        </FAQColumn>
        )}
      </FAQContainer>

      {acf.button && (
        <ButtonWrapper>
          <ExpandButton
            {...acf.button}
            icon={null}
          >
            {icon && (
              <SvgIcon
                icon={icon}
              />
            )}
          </ExpandButton>
        </ButtonWrapper>
      )}
    </SectionWrapper>
  );
};

PureFAQSection.propTypes = {
  acf: PropTypes.shape({
    numberOfColumns: PropTypes.number,
    title: PropTypes.string,
    faqs: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          post_content: PropTypes.string,
          post_title: PropTypes.string,
          acf: PropTypes.shape({
            structuredDataPage: PropTypes.string,
          }),
        }),
      ).isRequired,
    }),
    button: PropTypes.shape(LinkBlock.propTypes),
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
  variables: PropTypes.shape(),
  location: PropTypes.shape().isRequired,
};

PureFAQSection.defaultProps = {
  variables: {},
};

const PureFAQSectionSquished = withSquishing(PureFAQSection);

const FAQSection = (props) => {
  const { optionVariables } = useOptions();
  return (
    <PureFAQSectionSquished
      {...props}
      variables={optionVariables}
    />
  );
};

export { PureFAQSectionSquished as PureFAQSection };

export default FAQSection;
