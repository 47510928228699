import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@mantine/core';
import style from './style';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import devices from '../../../js/styles/Devices';
import useDevice from '../../../js/utils/use-device';
import { sendEvent } from '../../../js/utils/gtm';
import LinkBlock from '../../blocks/LinkBlock';
import NewFormBlock from '../../blocks/NewFormBlock/SE/advisa/NewFormBlock';

const Section = styled.section`
  height: 90vh;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${devices.downFromSmallTablet} {
    margin-top: -1px;
    height: 90vh;
    flex-direction: column;
    background-image: url(${({ mobileBackground }) => mobileBackground});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 60%;
  padding: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${devices.downFromSmallTablet} {
    max-width: 100%;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${style.contentWrapperBgOverlay};
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  background-color: #f0f0f0;
  background-image: url(${({ background }) => background});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 101%;
  position: relative;
  will-change: transform;
  transition: background-image 0.3s ease-in-out;

  @media ${devices.downFromSmallTablet} {
    display: none;
  }

  &::after {
    content: '';
    background-image: url(${({ foreground }) => foreground});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
`;

const Title = styled.h1`
  @media ${devices.downFromSmallTablet} {
    color: ${style.mobileTitleColor};
    font-size: 2rem!important;
  }
`;

const Text = styled.p`
  text-decoration: none;
`;

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1.25rem;

  @media ${devices.downFromTablet} {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const CustomButton = styled(Button)`
  background-color: var(--wallet-color-neutrals-white);
  border: calc(0.0625rem * var(--mantine-scale)) solid var(--wallet-color-primary-shade-900);
  color: var(--wallet-color-primary-shade-900);
`;

const ButtonLink = styled(LinkBlock)`
  cursor: pointer;
  text-decoration: none;

  span {
    color: ${style.buttonTextColor};
  }
`;

const TrustBox = styled.aside`
  position: absolute;
  bottom: 5%;
  right: 2.5%;
  max-width: 28rem;
  max-height: 7rem;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  border-radius: 8px;
  background: ${style.trustBoxBg};

  @media ${devices.downFromTablet} {
    display: none;
  }
`;

const TrustBoxImage = styled.img`
  width: 55px;
  height: 55px;
`;

const TrustBoxText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  p {
    margin: 0 !important;
  }
`;

const IndexSection = React.memo(({ acf }) => {
  const { isTabletAndUp } = useDevice();

  const handleButtonClick = useCallback(() => {
    sendEvent({
      event: 'user_openForm_click',
      content_id: 'onboarding_form_2',
    });
  }, []);

  if (!acf) return null;

  const {
    backgroundImage,
    foregroundImage,
    mobileBackgroundImage,
    indexTitle,
    indexText,
    openFormButton,
    buttonLink,
    trustBoxText,
    trustBoxImage,
  } = acf;

  const backgroundImageUrl = backgroundImage?.image?.node?.sourceUrl
    || backgroundImage?.svgImage?.node?.sourceUrl;

  const foregroundImageUrl = foregroundImage?.image?.node?.sourceUrl
    || foregroundImage?.svgImage?.node?.sourceUrl;

  const mobileBackgroundImageUrl = mobileBackgroundImage?.image?.node?.sourceUrl
    || mobileBackgroundImage?.svgImage?.node?.sourceUrl;

  const trustBoxImageUrl = trustBoxImage?.image?.node?.sourceUrl
    || trustBoxImage?.svgImage?.node?.sourceUrl;

  const openFormButtonText = openFormButton || 'Start the application';

  return (
    <>
      <Section mobileBackground={mobileBackgroundImageUrl}>
        <ContentWrapper>
          <Title dangerouslySetInnerHTML={createMarkup(indexTitle)} />
          {isTabletAndUp && <Text dangerouslySetInnerHTML={createMarkup(indexText)} />}
          <ButtonsWrapper>
            <Button type="button" onClick={handleButtonClick}>
              <span>{openFormButtonText}</span>
            </Button>
            {buttonLink && (
              <CustomButton variant="outline">
                <ButtonLink link={buttonLink?.link} />
              </CustomButton>
            )}
          </ButtonsWrapper>
        </ContentWrapper>

        <ImageWrapper
          background={backgroundImageUrl}
          foreground={foregroundImageUrl}
        />
        <TrustBox>
          {trustBoxImageUrl && <TrustBoxImage src={trustBoxImageUrl} alt="TrustBox" />}
          <TrustBoxText dangerouslySetInnerHTML={createMarkup(trustBoxText)} />
        </TrustBox>
      </Section>
      <NewFormBlock />
    </>
  );
});

IndexSection.propTypes = {
  acf: PropTypes.shape({
    backgroundImage: PropTypes.shape({
      image: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
      svgImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
    }),
    foregroundImage: PropTypes.shape({
      image: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
      svgImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
    }),
    mobileBackgroundImage: PropTypes.shape({
      image: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
      svgImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
    }),
    indexTitle: PropTypes.string,
    indexText: PropTypes.string,
    openFormButton: PropTypes.string,
    buttonLink: PropTypes.shape({
      link: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.string,
      }),
      icon: PropTypes.arrayOf(PropTypes.string),
    }),
    trustBoxText: PropTypes.string,
    trustBoxImage: PropTypes.shape({
      image: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
      svgImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export default IndexSection;
